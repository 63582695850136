@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("./components/ui-patterns/Button/button-styles.css");
@import url("./components/ui-patterns/Input/input-styles.css");

body {
    @apply bg-white;
}

@media screen and (max-width: 800px) {
    .navbar-container {
        background-image: none !important;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @apply font-product;
    @apply max-sm:font-product max-sm:font-medium !important;
    /* font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
}

.view-container {
    max-width: 1200px;
    @apply mx-auto;
}

.section-container {
    @apply py-24;
}


/* Hide scrollbar for Chrome, Safari and Opera */
.example::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE and Edge */
.example {
    -ms-overflow-style: none;
}

::-webkit-scrollbar {
    width: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

#btn-back-to-top {
    position: fixed;
}

/* ReactModal Responsivenss CSS Override */
@media screen and (min-width: 600px) {
    .ReactModal__Overlay--after-open {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .ReactModal__Content {
        width: 400px !important;
        margin-right: auto;
        margin-left: auto;
    }
}

/* Markdown Blogs Styling */

.blog-content-body-container {
    @apply my-8;
}

.blog-content-body-container > div > h1 {
    @apply text-3xl font-semibold;
}

.blog-content-body-container > div > h2 {
    @apply text-xl font-semibold;
}

.blog-content-body-container > div > h3 {
    @apply text-lg font-semibold;
}

.blog-content-body-container > div > ul > li {
    @apply list-inside list-disc;
}

.blog-content-body-container > div > * {
    @apply my-4;
}

.two-column-dropdown-menu {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two equal columns */
    grid-gap: 10px; /* Space between columns */
    /* remove on hover shadow  */
    box-shadow: none;
}

.two-column-dropdown-menu .ant-dropdown-menu-item {
    display: block; /* Ensure menu items take full width */
}

#disconnected-card > div > button > .button-shadow-container {
    width: 100%;
}

/* Overriding hover styles for dropdown menu items */
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-item-only-child:hover {
    background-color: transparent !important; /* Removes the gray background color on hover */
    box-shadow: none !important; /* Removes any shadow effect on hover */
    color: inherit !important; /* Keeps the text color unchanged on click */
}

.ant-menu-item-selected,
.ant-menu-item-active {
    background-color: transparent !important; /* Removes the gray background color on hover */
    box-shadow: none !important; /* Removes any shadow effect on hover */
    color: inherit !important; /* Keeps the text color unchanged on click */
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 0 !important;
}


