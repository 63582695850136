
.clamp_button {
    @apply flex flex-row items-center justify-center gap-2 border;
    @apply transition-all;
}

.clamp_button-primary {
    @apply bg-black text-white border-transparent hover:bg-zinc-800;
}

.clamp_button-outline {
    @apply bg-transparent text-black border-gray-300 hover:bg-gray-50;
}

.clamp_button-small {
    @apply px-3 py-1.5 rounded font-medium text-base;
}

.clamp_button-medium {
    @apply px-4 py-2 rounded font-medium text-base max-sm:px-3 max-sm:py-1.5 max-sm:rounded;
}

.clamp_button-large {
    @apply px-6 py-3 rounded-md font-semibold text-lg max-sm:px-3 max-sm:py-1.5 max-sm:rounded max-sm:text-base;
}